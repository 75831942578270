<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <course-progress-card :data="GET_ONE_RELATION" />
      </b-col>
      <b-col cols="12">
        <div
          class="list-group list-group-flush mt-2"
          tag="ul"
        >
          <div
            v-for="listItem in list"
            :key="`key-${listItem.id}`"
          >
            <b-list-group-item
              v-b-toggle="`coll${listItem.id}`"
              tag="li"
              class="drag-style"
            >
              <div class="ml-25">
                <b-card-text class="font-weight-bold mb-0 d-flex drag-text">
                  <h5 class="text-uppercase p-0 m-0">
                    {{ listItem.title }}
                  </h5>
                </b-card-text>
              </div>
            </b-list-group-item>
            <b-collapse
              :id="`coll${listItem.id}`"
              :visible="true"
            >
              <b-card class="mx-2">
                <b-row>
                  <b-col cols="12">
                    <b-table
                      :items="listItem.lessons"
                      :fields="fields"
                      @row-clicked="(el) => $router.push(`/course/${$route.params.id}/view/${el.id}`)"
                    >
                      <template #cell(type)="{value}">
                        Контент {{ value }}
                      </template>
                      <template #cell(information)>
                        <b-badge
                          class="p-75 mr-50"
                          variant="success"
                        >
                          <feather-icon
                            icon="PlayCircleIcon"
                            style="height: 20px; width: 20px;"
                          />
                        </b-badge>
                        <b-badge
                          class="p-75"
                        >
                          <feather-icon
                            icon="ClipboardIcon"
                            style="height: 20px; width: 20px;"
                          />
                        </b-badge>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BListGroupItem,
  BCardText,
  BCollapse,
  VBToggle,
  BCard,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import courseProgressCard from '../components/courseProgressCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BListGroupItem,
    BCard,
    BTable,
    BBadge,
    BCollapse,
    courseProgressCard,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      lessonData: {},
      editedTheme: {},
      lessonParentId: null,
      isEdit: false,
      isChapter: true,
      table: [],
      chapter: null,
      card: {},
      dataToEdit: {},
      fields: [
        {
          label: 'Название урока',
          key: 'title',
        },
        {
          label: 'Тип',
          key: 'type',
        },
        {
          label: 'Информация',
          key: 'information',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_ONE_COURSE', 'GET_COURSE_CHAPTERS', 'GET_ONE_RELATION']),
    list: {
      get() {
        return this.GET_COURSE_CHAPTERS
      },
      set(val) {
        this.SET_CHAPTERS(val)
      },
    },
  },
  async mounted() {
    await this.FETCH_ONE_COURSE(this.$route.params.id).then(() => {
      this.updateBreadcrumb()
      this.list = this.GET_COURSE_CHAPTERS
    })
    this.FETCH_ONE_RELATION(this.$route.params.relationId)
  },
  methods: {
    ...mapActions('courses', ['FETCH_ONE_COURSE', 'FETCH_ONE_RELATION']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapMutations('courses', ['SET_CHAPTERS']),
    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = this.GET_ONE_COURSE.title
      this.UPDATE_BREADCRUMB([first, second])
    },
  },
}
</script>

<style>
.drag-style {
      padding: 1rem 2.8rem 1rem 1rem;
      margin-bottom: 1rem;
}
.drag-text {
    justify-content: center;
    align-items: center;
}
</style>
